<template>
    <div class="flyout-item">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'FlyoutItem',
};
</script>

<style lang="scss" scoped>
.flyout-item {
    display: flex;
    flex-direction: row;
    min-width: rem(275);
    margin-top: rem(16);
}
</style>
