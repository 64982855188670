<template>
    <div class="flyout-menu">
        <template v-if="navigation">
            <div v-if="!isEmpty(navigation)" class="second-level-menu">
                <NuxtLink
                    v-for="(subcategory, index) in navigation?.children?.slice(offset, navigation?.children?.length)"
                    :key="subcategory.id"
                    :class="{'flyout-menu__item': true, active: activeIndex === index}"
                    :to="`${NuxtLinkNameTypes.CATEGORY}${subcategory.url}`"
                    data-long-press-delay="500"
                    @pointerenter="!isTouchDevice() && pointerEnter(index)"
                    @click="linkClicked"
                    @contextmenu.prevent
                    @long-press="activeIndex = index"

                >
                    <div class="flyout-menu__text">{{ subcategory.title }}</div>
                    <AtomIcon name="chevron-right" />
                </NuxtLink>
            </div>

            <div v-if="activeIndex !== -1 && !isEmpty(navigation.children[activeIndex])" class="third-level-menu">
                <nuxt-link
                    v-for="subcategory in navigation.children[activeIndex + offset].children"
                    :key="subcategory.id"
                    class="flyout-menu__item"
                    :to="`${NuxtLinkNameTypes.CATEGORY}${subcategory.url}`"
                    @click="linkClicked"
                >
                    <div class="flyout-menu__text">{{ subcategory.title }}</div>
                </nuxt-link>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import type { NavigationCategory } from '~/composables/types/api/searchDiscover/header';
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes';
import { isTouchDevice } from '~/composables/utils/useBrowserUtils';

const props = withDefaults(defineProps<{
    navigation: NavigationCategory | null;
    offset: number;
}>(), {
    offset: 0,
});

const emit = defineEmits<{(
    e: 'linkClicked'): void}>(
    );

const activeIndex = ref(0);

const isEmpty = (navigation: NavigationCategory | null) => navigation && navigation.children.length === 0;

const pointerEnter = (index: number) => {
    activeIndex.value = index;
};

const linkClicked = () => {
    emit('linkClicked');
};

watch(() => props.navigation, () => {
    activeIndex.value = 0;
});
</script>

<style lang="scss" scoped>
.flyout-menu {
    max-height: 50vh;
    z-index: $setting-zi-overlay;
    position: absolute;
    top: rem(40);
    box-shadow: 0 rem(4) rem(4) rem(-3) $setting-color-black;
    width: 100%;
    max-width: $setting-grid-max-width;
    margin: auto;
    display: flex;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        height: rem(56);
        cursor: pointer;
        user-select: none;
        -webkit-touch-callout: none;

        @include helper-color(state-default);
        @include helper-font-size(big);

        &.active {
            @include helper-color-bg(light-gray);
            @include helper-font-weight(medium);
        }
    }

    &__text {
        padding-left: sp(s);
    }

    .second-level-menu {
        overflow-y: auto;
        overscroll-behavior: none;
        width: 35%;
        display: flex;
        flex-direction: column;

        @include helper-color-bg(white);
    }

    .third-level-menu {
        overflow-y: auto;
        overscroll-behavior: none;
        width: 65%;
        padding: sp(xxs) 0;
        display: flex;
        flex-direction: column;

        @include helper-color-bg(light-gray);

        .flyout-menu__item {
            vertical-align: middle;
            width: 45%;
            height: rem(36);
            justify-content: flex-start;
            align-items: center;

            @include helper-font-weight(regular);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
