import getPlatforms from '~/composables/api/searchDiscover/getPlatforms';
import type { Platform } from '~/composables/types/api/searchDiscover/header';

export default function() {
    const result = ref<Array<Platform>>();

    const loadPlatforms = async() => {
        result.value = await getPlatforms();
    };

    return {
        loadPlatforms,
        platforms: computed(() => result.value),
    };
}
