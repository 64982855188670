<template>
    <li
        class='account-button'
        @pointerenter="!isTouchDevice() && pointerEnterEvent()"
        @pointerleave="!isTouchDevice() && pointerLeaveEvent()"
    >
        <AtomButton
            class="header__action"
            type="tertiary"
            size="reset"
            @click="navigateToAccount($event)"
        >
            <AtomIcon class="header__icon" name="user" width="20" />
            <AtomIcon class="arrow-down-icon header__icon--dropdown-arrow" name="chevron-down" />
            <span class="header__action__text">
                {{ $t('General.customer_account') }}
            </span>
        </AtomButton>

        <AtomFlyout
            v-if="userData"
            v-model="flyoutOpen"
            :close-on-outside-click="isTouchDevice()"
            @update:model-value="flyoutOpen = $event"
        >
            <template v-if="!isLoading">
                <div class='flyout-container'>
                    <div class='account-flyout__sub-nav-inner'>
                        <div class='account-flyout__sub-nav-title-container'>
                            <AtomIcon class='header__icon' name='user' width="24"/>
                            <h4 class='account-flyout__sub-nav-title'>
                                <span>{{ $t('General.customer_account') }}</span>
                            </h4>
                            <h4 class='account-flyout__sub-nav-title'>
                                {{ userData.firstName }} {{ userData.lastName }}
                            </h4>
                        </div>
                        <ul class='account-flyout__list-items'>
                            <li v-role:not="RoleTypes.SUPPLIER" class='account-flyout__list-items_item'>
                                <nuxt-link
                                    :to="NuxtLinkNameTypes.ADDRESSES_PAGE"
                                    @click="flyoutOpen=false"
                                >
                                    <AtomIcon name='home' width="24" />
                                    <span>{{ $t('General.addresses') }}</span>
                                </nuxt-link>
                            </li>
                            <li v-if="$can(PermissionTypes.ORDER) || $hasRole(RoleTypes.MEMBER)" class='account-flyout__list-items_item'>
                                <nuxt-link
                                    :to="NuxtLinkNameTypes.ORDER_HISTORY_PAGE"
                                    @click="flyoutOpen=false"
                                >
                                    <AtomIcon name='shopping-cart' width="24" />
                                    <span>{{ $t('General.orders') }}</span>
                                </nuxt-link>
                            </li>
                            <li v-if="$can(PermissionTypes.ORDER) || $hasRole(RoleTypes.MEMBER)" class='account-flyout__list-items_item'>
                                <nuxt-link
                                    :to="NuxtLinkNameTypes.CARTS_PAGE"
                                    @click="flyoutOpen=false"
                                >
                                    <AtomIcon name='basket' width="24" />
                                    <span>{{ $t('Cart.shopping_carts') }}</span>
                                </nuxt-link>
                            </li>
                            <li
                                v-if="$can(PermissionTypes.ORDER) || $hasRole(RoleTypes.MEMBER)"
                                v-role:not="RoleTypes.SUPPLIER"
                                class='account-flyout__list-items_item'
                            >
                                <nuxt-link
                                    :to="NuxtLinkNameTypes.CONDITION_SHEETS_PAGE"
                                    @click="flyoutOpen=false"
                                >
                                    <AtomIcon name='conditions' width="24" />
                                    <span>{{ $t('General.condition_sheets') }}</span>
                                </nuxt-link>
                            </li>
                            <li
                                v-if="$can(PermissionTypes.ORDER) || $hasRole(RoleTypes.MEMBER)"
                                v-role:not="RoleTypes.SUPPLIER"
                                class='account-flyout__list-items_item'
                            >
                                <nuxt-link
                                    :to="NuxtLinkNameTypes.BRANCH_CLUSTERS"
                                    @click="flyoutOpen=false"
                                >
                                    <AtomIcon name='cluster' width="24" />
                                    <span>{{ $t('General.branch_clusters') }}</span>
                                </nuxt-link>
                            </li>
                        </ul>
                    </div>
                    <div class='account-flyout__sub-nav-actions'>
                        <div class='grid grid--gap grid--justify grid--nowrap-lg-only'>
                            <div class='col'>
                                <AtomButton
                                    type='secondary'
                                    size='m'
                                    icon='log-out'
                                    :text="$t('General.logout')"
                                    :icon-right='true'
                                    @click='handleLogoutClick'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div v-else class="flyout-container">
                <AtomSpinner />
            </div>
        </AtomFlyout>
    </li>
</template>

<script lang="ts" setup>
import type { Customer } from '~/composables/types/api/searchDiscover/customer';
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes';
import { PermissionTypes } from '~/composables/types/permissionTypes';
import { RoleTypes } from '~/composables/types/roleTypes';
import { isTouchDevice } from '~/composables/utils/useBrowserUtils';

const { $can, $hasRole } = useNuxtApp();
const { getUserData, logout, userData } = useUser();
const toasts = useToasts();
const mobileAvailablePxRatio = 768;
const flyoutOpen = ref(false);
const isLoading = ref(false);
let defaultReferenceWidth = 0;

if (typeof window !== 'undefined') {
    defaultReferenceWidth = window.innerWidth;
}

const pointerEnterEvent = () => {
    const isModalAvailable = mobileAvailablePxRatio <= defaultReferenceWidth;

    if (!isModalAvailable || isTouchDevice()) {
        return;
    }

    flyoutOpen.value = true;
};

const pointerLeaveEvent = () => {
    flyoutOpen.value = false;
};

const handleLogoutClick = () => {
    logout();
    window.location.href = '/';
};

const navigateToAccount = async(e: MouseEvent) => {
    if (isTouchDevice() || (e.target as HTMLElement).closest('.header__icon--dropdown-arrow')) {
        flyoutOpen.value = !flyoutOpen.value;

        return;
    }

    await navigateTo($hasRole(RoleTypes.SUPPLIER) ? NuxtLinkNameTypes.ORDER_HISTORY_PAGE : NuxtLinkNameTypes.ADDRESSES_PAGE);
    flyoutOpen.value = false;
};

const areValuesNullOrEmpty = (obj: Customer): boolean => Object.values(obj).every(value => value === null || value === '');

onMounted(() => {
    watch(flyoutOpen, async(newValue: boolean) => {
        if (!newValue || (userData?.value && !areValuesNullOrEmpty(userData.value))) {
            return;
        }

        isLoading.value = true;
        await getUserData();
        isLoading.value = false;

        if (!userData.value) {
            toasts.add('LOAD_ERROR');
        }
    });
});
</script>

<style lang='scss' scoped>
.account-button {
    position: relative;
}

.header {
    svg {
        @include helper-color(corporate-blue);
    }

    &__action {
        cursor: pointer;
        background-color: transparent;

        @include helper-color(corporate-blue);

        .svg-icon {
            line-height: 1;
        }

        &__text {
            display: block;
            font-size: map-get($setting-font-size, default);
            font-weight: map-get($setting-font-weight, regular);
            margin-top: 0.375rem;
            white-space: nowrap;
        }
    }

    &__icon {
        @include helper-color(corporate-blue);
    }
}

.flyout-container {
    width: rem(462);

    @include helper-color-bg(white);

    :deep(.spinner) {
        margin: 0 auto;

        @include helper-color(gray-2);
    }
}

.account-flyout {
    position: relative;

    &__container {
        padding: rem(27.6) rem(18) rem(21);
        position: absolute;
        left: rem(-220);

        &::before {
            content: '';
            position: absolute;
            left: initial;
            top: rem(-5);
            right: 40%;
            width: rem(10);
            height: rem(10);
            transform: translateX(-50%) rotate(45deg);

            @include helper-border(($setting-color-gray-2 transparent transparent $setting-color-gray-2));
        }

        @include helper-breakpoint-media-max($lg - 1px) {
            display: none;
        }
    }

    &__sub-nav-title-container {
        padding-bottom: rem(10);
        display: flex;
        flex-direction: row;
    }

    &__sub-nav-title {
        padding-left: rem(5);

        @include helper-color-bg(white);
        @include helper-font-line-height(4);
        @include helper-font-weight(medium);
        @include helper-color(dark-blue);
    }

    &__sub-nav-user {
        padding-left: rem(5);

        @include helper-color-bg(white);
        @include helper-font-line-height(4);
        @include helper-font-weight(medium);
        @include helper-color(corporate-blue);
    }

    &__sub-nav-inner {
        overflow: hidden;
        overflow-y: auto;
    }

    &__list-items {
        padding-top: rem(5);
        margin-right: sp(xs);
        cursor: pointer;

        &_item {
            height: rem(58);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @include helper-border-t($setting-color-gray-1);

            svg {
                position: relative;
            }

            span {
                padding-left: rem(5);
                text-decoration: underline;

                @include helper-color(corporate-blue);
                @include helper-font-weight(medium);
            }

            button svg {
                @include helper-color(white);
            }
        }
    }

    &__sub-nav-actions {
        margin-top: rem(10);

        @include helper-svg-size(16);

        .user-navigation__action {
            display: flex;
            justify-content: center;
            align-items: center;

            @include helper-color(dark-blue);

            span {
                padding-left: rem(7);

                @include helper-font-line-height(4);
                @include helper-color(corporate-blue);
            }
        }
    }
}

.user-navigation {
    position: relative;
}
</style>
