import getCatalogSearchSuggestions from '~/composables/api/searchDiscover/getCatalogSearchSuggestions';
import type { CatalogSearchSuggestionsResult } from '~/composables/types/api/searchDiscover/catalogSearchSuggestions';
import type { ApiResponse } from '~/composables/types/api/apiResponse';

export default function() {
    let result: CatalogSearchSuggestionsResult | ApiResponse;
    let abortController: AbortController;

    const load = async(query: string): Promise<boolean> => {
        abortController = new AbortController();
        result = await getCatalogSearchSuggestions(query, { signal: abortController.signal });

        return !result.error;
    };

    const abort = (): void => {
        if (abortController) {
            abortController.abort();
        }
    };

    const getResult = (): CatalogSearchSuggestionsResult | ApiResponse => result;

    const wasAborted = (): boolean => abortController?.signal?.aborted;

    return {
        load,
        getResult,
        abort,
        wasAborted,
    };
}
