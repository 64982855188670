import { useCookieConsents } from '~/composables/stores/useCookieConsents';

const usercentrics = (window as any).usercentrics;
const consentsState = useCookieConsents();

export default function useUsercentricsCookieConsents() {
    const config = useRuntimeConfig();

    if (config.public.userCentricsIsActive) {
        const initialize = () => {
            usercentrics.getConsents().forEach((consent: any) => {
                consentsState.update(consent.consentStatus, consent.dataProcessingService);
            });

            window.addEventListener('ucEvents', (e: any) => {
                if (e.data.event === 'consent_changed') {
                    usercentrics.getConsents().forEach((consent: any) => {
                        consentsState.update(consent.consentStatus, consent.dataProcessingService);
                    });

                    /*
                     * TODO: Update Consent dynamicaly without page refresh
                     * gtag('consent', 'update', {
                     *     ad_storage: consentsState.get('Google Analytics') ? 'granted' : 'denied',
                     *     analytics_storage: consentsState.get('Google Analytics') ? 'granted' : 'denied',
                     *     personalization_storage: 'denied',
                     *     functionality_storage: 'denied',
                     *     security_storage: 'denied',
                     *     matomo_storage: consentsState.get('Matomo') ? 'granted' : 'denied',
                     *     hotjar_storage: consentsState.get('Hotjar') ? 'granted' : 'denied',
                     * });
                     */

                    window.location.reload();
                }
            });
        };

        if (usercentrics.isInitialized) {
            initialize();
        } else {
            usercentrics.onViewInit = () => {
                initialize();
            };
        }
    }
}
