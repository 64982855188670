<template>
    <AtomMetaBarButton
        v-if="platforms"
        :text="useStore().isNoStore() ? $t('General.reorder_portal') : $t('General.pre_order_portal')"
        icon="brand-mark"
        :show-arrow="true"
        :arrow-up="flyoutOpen"
        @click="flyoutOpen = !flyoutOpen"
    >
        <div class="flyout-container">
            <AtomFlyout v-model="flyoutOpen" :close-on-outside-click="true" arrow-position="left" :margin="20">
                <AtomFlyoutHeader :text="$t('General.platforms')" />
                <AtomFlyoutItem v-for="(store, index) in (platforms || [])" :key="index">
                    <a :href="store.attributes.link">
                        {{ store.attributes.name }}
                    </a>
                </AtomFlyoutItem>
            </AtomFlyout>
        </div>
    </AtomMetaBarButton>
</template>

<script lang="ts" setup>
import useStore from '~/composables/service/useStore';

const platformsStore = usePlatformsStore();
const { $t } = useNuxtApp();
const { platforms } = storeToRefs(platformsStore);
const flyoutOpen = ref(false);

onMounted(async() => {
    await platformsStore.getPlatforms();
});
</script>

<style lang="scss" scoped>
.flyout-container {
    position: absolute;
}

.flyout-item a {
    display: flex;
    align-items: center;
    color: $setting-color-dark-blue;

    svg {
        margin-left: rem(6);

        @include helper-color(corporate-blue);
    }
}
</style>
